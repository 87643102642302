<template>
  <div class="reset-password fill-height mt-1 thank-you-page">
    <v-card v-if="showForm" class="elevation-0">
      <div class="d-flex align-center flex-column mb-3">
        <CategoryTitle :category="category" />
        <h3 class="font-weight-regular login-sub">
          INSERISCI L' EMAIL CON CUI TI SEI REGISTRATO
        </h3>
      </div>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-form
          v-on:submit.prevent
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-text-field
            v-model="email"
            label="Indirizzo email *"
            required
            outlined
            :rules="emailRules"
            dense
            clearable
            @keyup.enter="handleResetPasswordRequest"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-column align-center mt-2 register-wrapper">
          <!-- to="/register" -->
          <v-btn
            @click="handleResetPasswordRequest"
            class="reset-button"
            color="primary"
            large
            depressed
            :loading="loading"
            :disabled="!valid"
            >{{ $t("login.submitResetPassword") }}</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>

    <v-card v-if="!showForm" class="elevation-0">
      <div class="d-flex align-center flex-column mb-3">
        <h1 class="reset-title">RECUPERA PASSWORD</h1>
      </div>
      <v-spacer></v-spacer>
      <v-card-text class="response-messages">
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-column align-center mt-2 register-wrapper">
          <!-- <v-btn
                  @click="resetPage"
                  class="reset-button"
                  color="$white"
                  large
                  depressed
                  :loading="loading"
                  :disabled="!valid"
                >
                  {{ $t("common.back") }}
                </v-btn> -->
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
.response-messages .v-alert {
  &.v-alert--outlined {
    border: none;
  }
}
</style>
<script>
import RegistrationService from "@/service/iperalCustomService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";
import { requiredValue, isEmail } from "@/validator/validationRules";
import category from "~/mixins/category";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPasswordRequest",
  mixins: [category],
  data() {
    return {
      email: "",
      valid: true,
      loading: false,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      response: {},
      showForm: true
    };
  },
  methods: {
    async handleResetPasswordRequest(e) {
      let _this = this;
      e.preventDefault();
      _this.response = {};
      if (_this.valid) {
        _this.loading = true;
        try {
          const res = await RegistrationService.getResetPasswordToken(
            _this.email
          );
          if (res.response) {
            //vm.response = res.response;
            _this.response = res.response;
            _this.$router.push({ name: "ResetPasswordSent" });
          }
        } catch (err) {
          if (err.response) {
            _this.response = err.response.data.response;
          } else {
            _this.response = {
              errors: [{ error: "Email non trovata" }],
              infos: [],
              status: 0
            };
          }
        } finally {
          _this.showForm = false;
          _this.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
      this.showForm = true;
    }
  }
};
</script>

<style></style>
